import React from 'react';
import Axios from 'axios';
import Config from '../Config.json';

import ImageZoom from '../Components/ImageZoomHover';

function ShareVideo(props) {
    return (
        <div className="content-creator-share">
            <h1 className="poc-small-title darkblue text-centered">{props.title}</h1>
            <div className="creator-social social-logos">
                <a href={props.insta} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.43 224.43" width="60px" height="60px">
                                <g fill="#61DAFB">
                                    <path d="M72.18,0c26.5,0,53,0,79.5,0c3.2,0.27,6.39,0.64,9.59,0.79c35.15,1.72,57.71,22.66,61.84,57.51 c0.57,4.8,0.88,9.63,1.32,14.44c0,26.5,0,53,0,79.5c-0.26,3.09-0.58,6.17-0.79,9.26c-1.07,15.82-5.11,30.5-16.08,42.59 c-12.38,13.64-28.39,19.61-46.17,19.92c-32.96,0.57-65.94,0.51-98.9,0.01c-15.7-0.24-30.23-5.06-42.21-15.94 C6.65,195.71,0.71,179.69,0.4,161.91c-0.57-32.96-0.49-65.94-0.01-98.9c0.22-15.16,4.78-29.27,14.98-41.07
							            C26.07,9.57,39.95,3.5,55.86,1.51C61.27,0.83,66.74,0.49,72.18,0z M204.55,112.74c-0.14,0-0.27-0.01-0.41-0.01
							            c0-1.75,0.04-3.5-0.01-5.25c-0.47-16.35-0.22-32.76-1.63-49.02c-1.73-20.03-13.86-32.9-33.7-36.04c-11.03-1.74-22.39-1.93-33.6-1.98
							            c-23.48-0.1-46.97-0.04-70.44,0.7c-25.3,0.8-40.5,13.66-43.37,36.49c-1.32,10.49-1.55,21.18-1.56,31.78
							            c-0.03,23.48-0.04,46.98,0.75,70.44c0.9,26.6,14.2,42.21,40.38,43.42c34.01,1.58,68.2,1.53,102.21-0.04
							            c25.17-1.16,37.48-14.65,39.74-39.95C204.42,146.52,204.07,129.59,204.55,112.74z M111.89,170.26
							            c-32.09-0.01-57.76-25.8-57.72-57.99c0.04-31.86,25.84-57.55,57.8-57.54c32.08,0.01,57.76,25.81,57.72,57.99
							            C169.65,144.58,143.85,170.27,111.89,170.26z M149.41,112.56c0.03-20.58-16.77-37.47-37.33-37.54
							            c-20.58-0.07-37.53,16.69-37.64,37.24c-0.11,20.74,16.8,37.75,37.52,37.73C132.54,149.97,149.38,133.13,149.41,112.56z
							            M158.45,52.36c-0.05,7.5,6.05,13.62,13.54,13.62c7.38-0.01,13.37-5.9,13.49-13.26c0.12-7.6-5.77-13.69-13.32-13.77
							            C164.58,38.88,158.49,44.84,158.45,52.36z"/>
                                </g>
                                </svg></a>
                <a href={props.fb} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.43 224.43" width="60px" height="60px">
                                <g fill="#61DAFB">
                                    <path d="M151.96,219.74c0-28.65,0-56.58,0-84.95c9.35,0,18.45,0,28.07,0c1.43-11.04,2.82-21.72,4.3-33.14c-10.98,0-21.54,0-32.3,0 c0-9.29-0.48-17.94,0.15-26.51c0.5-6.72,4.37-10,11.33-10.41c7.12-0.42,14.27-0.09,22.09-0.09c0-9.92,0.08-18.87-0.13-27.81 c-0.02-0.91-1.8-2.55-2.77-2.56c-11.29-0.1-22.8-1.3-33.84,0.43c-18.25,2.86-29.82,16.9-31.04,35.32 c-0.56,8.47-0.34,16.99-0.46,25.48c-0.03,1.79,0,3.58,0,5.85c-9.66,0-18.76,0-28.21,0c0,11.21,0,21.94,0,33.14 c9.12,0,18.21,0,27.76,0c0,28.5,0,56.52,0,84.96c-1.59,0.09-3.19,0.26-4.79,0.26c-23,0.02-46,0.04-68.99,0 c-25.44-0.04-43.05-17.61-43.08-43.08c-0.05-44.5-0.05-88.99,0-133.49C0.06,17.69,17.64,0.08,43.1,0.04 c44.5-0.06,88.99-0.06,133.49,0c25.53,0.03,43.12,17.6,43.14,43.01c0.03,44.5,0.03,88.99,0,133.49 c-0.02,25.75-17.55,43.16-43.43,43.21C168.33,219.76,160.36,219.74,151.96,219.74z" />
                                </g>
                                </svg></a>
                <a href={props.twitter} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.73 113.98" width="60px" height="60px">
                                <g fill="#61DAFB">
                                    <path d="M65.79,35.41c0.23-4.78-0.07-8.89,0.7-12.78c3.66-18.45,25.65-28.9,41.32-18.55c8.14,5.38,14.33,4.16,21.59,0.34 c0.58-0.31,1.31-0.31,3.86-0.88c-3.67,5.02-6.46,8.83-10.08,13.78c4.2-0.61,7.46-1.08,11.54-1.67c-1.39,1.98-2.09,3.94-3.47,4.79 c-7.39,4.58-8.45,11.58-8.98,19.5c-2.57,38.51-34.27,70.22-72.75,73.64C32.7,115.09,16.92,112.34,0,103.24 c14.86-1.86,27.73-3.76,38.9-13.43c-11.86-2.28-21.03-7.3-25.65-19.03c3.2-0.41,6.1-0.78,11.44-1.46 C11.55,63.38,3.39,55.65,2.89,41.77c3.82,0.81,7.14,1.51,12.62,2.67C3.67,33-0.52,21.34,6.09,6.25 C22.6,23.64,41.6,34.51,65.79,35.41z" />
                                    </g>
                                </svg></a>
                <a href={props.youtube} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.87 94.96" width="60px" height="60px">
                                <g fill="#61DAFB">
                                    <path d="M141.87,50.45c-0.9,7.54-1.44,15.13-2.76,22.6c-3.12,17.7-8.1,21.83-25.77,21.86c-27.72,0.06-55.44,0.07-83.16,0.01 C9.6,94.88,5.46,91.8,2.09,71.64c-3-18.01-2.85-36.2,0.99-54.13C6.02,3.8,10.88,0.09,25,0.05c31.07-0.09,62.13-0.04,93.2,0.02 c11.11,0.02,16.5,3.75,19.25,14.39c1.72,6.64,2.18,13.62,3.16,20.45c0.47,3.26,0.84,6.53,1.26,9.8 C141.87,46.62,141.87,48.54,141.87,50.45z M52.99,74.36c15.48-9.29,29.83-17.91,45.1-27.08c-15.6-9.31-29.97-17.89-45.1-26.91 C52.99,38.79,52.99,55.73,52.99,74.36z" />
                                </g>
                            </svg></a>
                      </div>
        </div>
    )
}

function Categories(props) {
    const categories = props.categories;
    return (
        <div className="page-width">
            {categories.map(category => (
                <div key={category.id} className="content-creator-category">
                    <h1 className="poc-small-title darkblue text-centered" >{category.name}</h1 >
                    <div className="creator-images">
                        {category.logos.map(img => (
                            <ImageZoom url={img.image !== null && img.image !== undefined && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + img.image.url} key={img.id} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

const categories = []
const creatorcontent = []

class Content extends React.Component {
    state = {
        categories,
        creatorcontent
    }

    async componentDidMount() {

        const categoriesRes = await Axios({
            method: 'GET',
            url: Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + '/image-categories'
        })
        const creatorcontentRes = await Axios({
            method: 'GET',
            url: Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + '/content-creators?idname=hacktag'
        })
        
        const categories = categoriesRes.data
        const creatorcontent = creatorcontentRes.data

        this.setState({ categories, creatorcontent })
    }

    render() {
        const { categories, creatorcontent } = this.state
        if (creatorcontent[0] !== undefined) {
            const content = creatorcontent[0];
        return (
            <div>
            <div className="page-width">
                <div className="block-about-intro">
                    <h1 className="poc-title darkblue text-centered">{content.title}</h1>
                    <p className="poc-text darkred text-centered">{content.text}</p>
                </div>
            </div>
                <div className="full-background follow-game" style={{ backgroundImage: `url(${(content.requestbg !== null && content.requestbg !== undefined) && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + "" + content.requestbg.url} )` }}>
                <div className="page-width">
                        <h1 className="poc-small-title white text-centered">{content.requesttitle}</h1>
                        <a className="rect-logo center-img" href={content.requestlink}><img alt="hacktag" src={content.requestimage !== null && content.requestimage !== undefined && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + content.requestimage.url} /></a>
                        <p className="poc-text white text-centered">{content.requesttext}</p>
                </div>
            </div>
                <div className="artwork-block">
                <div className="page-width block-about-intro artwork-block">
                        <h1 className="poc-title darkblue text-centered">{content.artworktitle}</h1>
                        <p className="poc-text darkred text-centered">{content.artworktext}</p>
                </div>
                <Categories categories={categories} />
                    <ShareVideo title={content.sharetitle} insta={content.shareoninstagram} twitter={content.shareontwitter} fb={content.shareonfacebook} youtube={content.shareonyoutube}/>
                </div>
            </div>
            );
        } else {
            return (
                <div>
                    The page data has not been found yet.
                </div>
            );
        }

    }
}

export default Content;

