import React from 'react';

import { TwitterTimelineEmbed } from 'react-twitter-embed';

import Steam from './SteamNews';


function TwitterNewsletter(props) {
    const game = props.game;
    
    return (
        <div className="page-width steam-twitter twitter-embed">
            <div className="lightgray-bg">
                <h3 className="poc-title blood" align="center">Twitter News</h3>
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName={game.TwitterScreenName}
                    options={{ height: 600 }}
                    theme="dark"
                />
            </div>

            <div className="lightgray-bg">
                <h3 className="poc-title blood" align="center">Newsletter</h3>
                <p align="center">
                <iframe width="500" height="600" src={game.NewsletterLink} frameborder="0" scrolling="no" allowfullscreen></iframe></p>
            </div>

        </div>
    )
}

export default TwitterNewsletter;