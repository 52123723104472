import React from 'react';
import Config from '../Config.json';

import Axios from 'axios';


function NewsItem(props) {
    const item = props.item;
    var d = new Date(item.date * 1000).toLocaleString();
    return (
        <div className="">
            <a href={item.url} target="_blank">
                <p className="poc-small-title darkblue">{item.title}</p>
                <p className="poc-text darkred">{item.contents}</p>
                <p className="poc-small-text darkred">By {item.author} - {d}</p>
            </a>
        </div>
    )
}

function NewsBlock(props) {
    const news = props.news;
    var items = [];
    if (news !== undefined) {
        items = news.datanews;
    }
    return (
        <div className="">
            {items.map(item => (
                <div key={item.id} className="steam-news-article">
                    <NewsItem item={item} />
                </div>
            ))}
        </div>
    )
}

const news = []

class News extends React.Component {
    state = {
        news
    }
    

    async componentDidMount() {

        const newsRes = await Axios({
            method: 'GET',
            url: Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + "/steam-news?Game=Hacktag"
        })
        
        const news = newsRes.data

        this.setState({ news })
    }

    render() {
        const { news } = this.state
        
        return (
            <div className="steam-bg">
                <h1 className="poc-title darkblue">Steam News</h1>
                <NewsBlock news={news[0]}/>
            </div>
        );
    }
}

export default News;