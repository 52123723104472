import React from 'react';
import Config from '../Config.json';

function Presentation(props) {
    const game = props.game;
    
    return (
        <div className="full-background follow-game" style={{ backgroundImage: `url(${Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + game.FollowBg.url} )` }}>
            <div className="page-width">
                <h1 className="poc-title white text-centered">Follow {game.name}</h1>
                <div className="hacktag-social-logos">                            
                    <a href={game.FacebookLink} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.43 224.43" width="60px" height="60px">
                                <g fill="#61DAFB">
                                    <path d="M151.96,219.74c0-28.65,0-56.58,0-84.95c9.35,0,18.45,0,28.07,0c1.43-11.04,2.82-21.72,4.3-33.14c-10.98,0-21.54,0-32.3,0 c0-9.29-0.48-17.94,0.15-26.51c0.5-6.72,4.37-10,11.33-10.41c7.12-0.42,14.27-0.09,22.09-0.09c0-9.92,0.08-18.87-0.13-27.81 c-0.02-0.91-1.8-2.55-2.77-2.56c-11.29-0.1-22.8-1.3-33.84,0.43c-18.25,2.86-29.82,16.9-31.04,35.32 c-0.56,8.47-0.34,16.99-0.46,25.48c-0.03,1.79,0,3.58,0,5.85c-9.66,0-18.76,0-28.21,0c0,11.21,0,21.94,0,33.14 c9.12,0,18.21,0,27.76,0c0,28.5,0,56.52,0,84.96c-1.59,0.09-3.19,0.26-4.79,0.26c-23,0.02-46,0.04-68.99,0 c-25.44-0.04-43.05-17.61-43.08-43.08c-0.05-44.5-0.05-88.99,0-133.49C0.06,17.69,17.64,0.08,43.1,0.04 c44.5-0.06,88.99-0.06,133.49,0c25.53,0.03,43.12,17.6,43.14,43.01c0.03,44.5,0.03,88.99,0,133.49 c-0.02,25.75-17.55,43.16-43.43,43.21C168.33,219.76,160.36,219.74,151.96,219.74z" />
                                </g>
                                </svg></a>
                    <a href={game.TwitterLink} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.73 113.98" width="60px" height="60px">
                                <g fill="#61DAFB">
                                    <path d="M65.79,35.41c0.23-4.78-0.07-8.89,0.7-12.78c3.66-18.45,25.65-28.9,41.32-18.55c8.14,5.38,14.33,4.16,21.59,0.34 c0.58-0.31,1.31-0.31,3.86-0.88c-3.67,5.02-6.46,8.83-10.08,13.78c4.2-0.61,7.46-1.08,11.54-1.67c-1.39,1.98-2.09,3.94-3.47,4.79 c-7.39,4.58-8.45,11.58-8.98,19.5c-2.57,38.51-34.27,70.22-72.75,73.64C32.7,115.09,16.92,112.34,0,103.24 c14.86-1.86,27.73-3.76,38.9-13.43c-11.86-2.28-21.03-7.3-25.65-19.03c3.2-0.41,6.1-0.78,11.44-1.46 C11.55,63.38,3.39,55.65,2.89,41.77c3.82,0.81,7.14,1.51,12.62,2.67C3.67,33-0.52,21.34,6.09,6.25 C22.6,23.64,41.6,34.51,65.79,35.41z" />
                                </g>
                        </svg></a>
                </div>
            
            </div>
        </div>
    )
}

export default Presentation;