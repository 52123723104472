import React from 'react';
import Config from '../Config.json';

import ImageZoom from '../Components/ImageZoomHover';

function Screenshots(props) {
    const screenshots = props.screenshots

    return (
        <div className="screenshots">
            {screenshots.map(screenshot => (                    
                <ImageZoom url={screenshot.image !== null && screenshot.image !== undefined && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + screenshot.image.url} key={screenshot.id} />
            ))}
        </div>
    )
}

function Block(props) {
    const screenshots = props.game.screenshots;
    
    return (
        <div className="page-width our-team-block">
            <Screenshots screenshots={screenshots} />
        </div>
    )
}


export default Block;