import React from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom'

/*CSS*/
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import './App.css';

/*Components*/
import Header from './Components/GameHeader';
import Footer from './Components/GameFooter';

/*Pages*/
import GameMainPage from './Pages/GameMainPage';
import ContentCreators from './Pages/ContentCreators';
import PressKit from './Pages/PressKit';

//const server = require('./server');
//server.initialize();

class App extends React.Component {
    
    render() {
        return (
            <Router>
                <div className="App">
                    <Header />

                    <Route exact path='/' component={GameMainPage} />
                    <Route exact path='/Content-Creators' component={ContentCreators} />
                    <Route exact path='/Press-Kit' component={PressKit} />

                    <Footer />

                </div>
            </Router>

        );
    }
}


export default App;
