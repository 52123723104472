import React from 'react';
import Config from '../Config.json';


function ArticlesBlock(props) {
    const articles = props.articles
    return (
        <div className="articles-detailed">
            {articles.map(article => (
                <div key={article.id}>
                    <a href={article.link}>
                        <h4 className="poc-text black text-centered">{article.title}</h4>
                        <img alt="hacktag" className="center-img article-image" src={article.image !== undefined && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + article.image.url} />
                    </a>
                </div>
            ))}
        </div>
    )
}

function Block(props) {
    const articles = props.game.articles;
    return (
        <div className="page-width">
            <ArticlesBlock articles={articles} />
        </div>
    )
}


export default Block;