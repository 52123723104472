import React from 'react';


function JoinCommunity(props) {
    const game = props.game;

    const hasTwitterLink = true;
    const hasDiscordLink = true;
    const hasSteamLink = false;

    return (
        <div className="darkred-bg full-background text-banner" >
            <div className="page-width">
                <h1 className="poc-title white text-centered">Join our community!</h1>
                <div className="hacktag-social-logos">

                    {hasTwitterLink ? (<a href={game.TwitterLink} target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.73 113.98" width="100px" height="100px">
                            <g fill="#000000">
                                <path d="M65.79,35.41c0.23-4.78-0.07-8.89,0.7-12.78c3.66-18.45,25.65-28.9,41.32-18.55c8.14,5.38,14.33,4.16,21.59,0.34 c0.58-0.31,1.31-0.31,3.86-0.88c-3.67,5.02-6.46,8.83-10.08,13.78c4.2-0.61,7.46-1.08,11.54-1.67c-1.39,1.98-2.09,3.94-3.47,4.79 c-7.39,4.58-8.45,11.58-8.98,19.5c-2.57,38.51-34.27,70.22-72.75,73.64C32.7,115.09,16.92,112.34,0,103.24 c14.86-1.86,27.73-3.76,38.9-13.43c-11.86-2.28-21.03-7.3-25.65-19.03c3.2-0.41,6.1-0.78,11.44-1.46 C11.55,63.38,3.39,55.65,2.89,41.77c3.82,0.81,7.14,1.51,12.62,2.67C3.67,33-0.52,21.34,6.09,6.25 C22.6,23.64,41.6,34.51,65.79,35.41z" />
                            </g>
                        </svg></a>) : (<div />)}

                    {hasDiscordLink ? (<a href={game.DiscordLink} target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100px" height="100px">
                            <g fill="#61DAFB">
                                <path d="M40 12s-4.586-3.59-10-4l-.488.977C34.406 10.176 36.652 11.89 39 14c-4.047-2.066-8.04-4-15-4-6.96 0-10.953 1.934-15 4 2.348-2.11 5.02-4.016 9.488-5.023L18 8c-5.68.535-10 4-10 4s-5.121 7.426-6 22c5.16 5.953 13 6 13 6l1.64-2.184C13.856 36.848 10.716 35.121 8 32c3.238 2.45 8.125 5 16 5s12.762-2.55 16-5c-2.715 3.121-5.855 4.848-8.64 5.816L33 40s7.84-.047 13-6c-.879-14.574-6-22-6-22zM17.5 30c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4zm13 0c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4z" />
                            </g>
                        </svg></a>) : (<div />)}

                    {hasSteamLink ? (<a href={game.SteamLink} target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233 233" width="100px" height="100px">
                            <g fill="#000000">
                                <path fill="url(#a)" d="m4.8911 150.01c14.393 48.01 58.916 82.99 111.61 82.99 64.34 0 116.5-52.16 116.5-116.5 0-64.341-52.16-116.5-116.5-116.5-61.741 0-112.26 48.029-116.25 108.76 7.5391 12.66 10.481 20.49 4.6411 41.25z" />
                                <path d="m110.5 87.322c0 0.196 0 0.392 0.01 0.576l-28.508 41.412c-4.618-0.21-9.252 0.6-13.646 2.41-1.937 0.79-3.752 1.76-5.455 2.88l-62.599-25.77c0.00049 0-1.4485 23.83 4.588 41.59l44.254 18.26c2.222 9.93 9.034 18.64 19.084 22.83 16.443 6.87 35.402-0.96 42.242-17.41 1.78-4.3 2.61-8.81 2.49-13.31l40.79-29.15c0.33 0.01 0.67 0.02 1 0.02 24.41 0 44.25-19.9 44.25-44.338 0-24.44-19.84-44.322-44.25-44.322-24.4 0-44.25 19.882-44.25 44.322zm-6.84 83.918c-5.294 12.71-19.9 18.74-32.596 13.45-5.857-2.44-10.279-6.91-12.83-12.24l14.405 5.97c9.363 3.9 20.105-0.54 23.997-9.9 3.904-9.37-0.525-20.13-9.883-24.03l-14.891-6.17c5.746-2.18 12.278-2.26 18.381 0.28 6.153 2.56 10.927 7.38 13.457 13.54s2.52 12.96-0.04 19.1m51.09-54.38c-16.25 0-29.48-13.25-29.48-29.538 0-16.275 13.23-29.529 29.48-29.529 16.26 0 29.49 13.254 29.49 29.529 0 16.288-13.23 29.538-29.49 29.538m-22.09-29.583c0-12.253 9.92-22.191 22.14-22.191 12.23 0 22.15 9.938 22.15 22.191 0 12.254-9.92 22.183-22.15 22.183-12.22 0-22.14-9.929-22.14-22.183z" />
                            </g>
                        </svg></a>) : (<div />)}

                </div>
            </div>
        </div>
    )
}

export default JoinCommunity;