import React from 'react';
import Axios from 'axios';
import Config from '../Config.json';
import delve from 'dlv';

const data = []

class GameData extends React.Component {
    state = {
        data
    }

    async componentDidMount() {

        var dbAuth = '';
        const database = delve(Config.mongo, 'database', null);
        const dbUser = delve(Config.mongo, 'user', null);
        const dbPass = delve(Config.mongo, 'pass', null);

        if (dbUser != null) {
            dbAuth += dbUser;
            if (dbPass != null) {
                dbAuth += ':' + dbPass;
            }
            dbAuth += '@';
        }

        const dataRes = await Axios({
            method: 'GET',
            url: 'http://' + dbAuth + Config.mongo.address + ':' + + Config.mongo.port + '/api/'+ database +'/status/'
        })
        

        const data = dataRes.data

        this.setState({ data })
    }


    render() {
        const { data } = this.state
        console.log("result", data)
        return (
            <div className="page-width block-data">
                <div class="data-line">
                    <p className="poc-small-title darkblue text-centered">Total Players</p>
                    <p className="poc-small-title darkred text-centered">{data.totalPlayers}</p>
                </div>
                <div class="data-line">
                    <p className="poc-small-title darkblue text-centered">Online Players</p>
                    <p className="poc-small-title darkred text-centered">{data.onlinePlayers}</p>
                </div>
            </div>
        );
    }
}


export default GameData;