import React from 'react';
import Config from '../Config.json';

function GamePresentation(props) {
    const game = props.game;
   
    return (
        <div className="full-background" style={{ backgroundImage: `url(${Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + game.PresentationBg.url} )` }}>
            <div className="page-width block-game-presentation">
                <div className="row left-first">
                    <div className="col-70">
                        <h1 className="poc-title white">{game.name}</h1>
                        <h4 className="poc-text white">
                            {game.descriptionComplete}
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="only-right-col center-img">
                        <img alt={'"'+game.name+'"'} className="black-shadow" src={Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + game.PresentationScreenshot.url} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GamePresentation;