import React from 'react';
import Axios from 'axios';
import Config from '../Config.json';


import Video from '../Components/Video';
import GameData from '../Components/GameData';
import Awards from '../Components/AwardsImage';
import GamePresentation from '../Components/GamePresentation';
import Follow from '../Components/FollowHacktag';
import Articles from '../Components/ArticlesDetailedList';
import JoinCommunity from '../Components/JoinCommunity';
import Screenshots from '../Components/ScreenShotsBlock';
//import TwitterSteam from '../Components/TwitterSteamNews';
import TwitterNewsletter from '../Components/TwitterNewsletter';

const game = [];

class HacktagHome extends React.Component {
    state = {
        game
    }

    async componentDidMount() {

        const gameRes = await Axios({
            method: 'GET',
            url: Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + '/games?name=' + Config.website.name
        })



        const game = gameRes.data

        this.setState({ game })
    }


    render() {
        const { game } = this.state

        const hasStatistics = false;
        const hasPresentation = false;
        const hasFollow = false;
        const hasAwards = false;
        const hasArticles = false;
        const hasCommunity = true;


        if (game[0] !== undefined) {
            const gamedata = game[0];
            return (
                <div className="black-bg">
                    <Video game={gamedata} />
                    {hasStatistics ? (<GameData />) : (<div className="GameData"></div>)}
                    {hasPresentation ? (<GamePresentation game={gamedata} />) : (<div className="GamePresentation"></div>)}
                    {hasFollow ? (<Follow game={gamedata} />) : (<div className="Follow"></div>)}
                    <TwitterNewsletter game={gamedata} twittername={gamedata.TwitterScreenName} />
                    {hasArticles ? (<Articles game={gamedata} />) : (<div className="Articles"></div>)}
                    {hasCommunity ? (<JoinCommunity game={gamedata} />) : (<div className="Articles"></div>)}
                    <Screenshots game={gamedata} />
                    {hasAwards ? (<Awards game={gamedata} />) : (<div className="Awards"></div>)}
                </div>
            );
        } else {
            return (
                <div>
                    Game not found. There was a problem with the database.
                </div>
            );
        }
    }
}

export default HacktagHome;