import React from 'react';
import Config from '../Config.json';


function Block(props) {
    const awards = props.game.awards;
    return (
        <div className="page-width block-award">
            <div className="awards-image">
                {awards.map(award => (
                    <div key={award.id}>
                        <img alt="hacktag-awards" src={award.image !== undefined && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + award.image.url} />
                    </div>
                ))}
            </div>
        </div>
    )
}


export default Block;