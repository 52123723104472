import React from 'react';
import Axios from 'axios';
import Config from '../Config.json';

import ReactPlayer from "react-player";

import Screenshots from '../Components/ScreenShotsBlock';
import ImageZoom from '../Components/ImageZoomHover';

function Paragraph(props) {
    return (
        <div id={props.name}>
            <h1 className="poc-small-title black">{props.title}</h1>
            <p className="poc-team-text black">{props.text}</p>
        </div>
    )
}

function Factsheet(props) {
    const game = props.game;
    return (
        <div id="factsheet" className="factsheet">
            <h1 className="poc-small-title black">{props.title}</h1>
            <div >
                <p className="poc-team-text black"><strong>Developer:</strong></p>
                {game.studios.map(studio => (
                    <div key={studio.id}>
                        <a href={studio.PressKit}><p className="poc-team-text black">{studio.name}</p></a>
                        <p className="poc-team-text black">Based in {studio.city}, {studio.country}</p>
                    </div>
                 ))}
            </div>
            <div >
                <p className="poc-team-text black"><strong>Release date:</strong></p>
                {game.releases.map(release => (
                    <div key={release.id}>
                        <p className="poc-team-text black">{release.ReleaseDate} on {release.Plateform}</p>
                    </div>
                ))}
            </div>
            <div >
                <p className="poc-team-text black"><strong>Platforms:</strong></p>
                {game.releases.map(release => (
                    <div key={release.id}>
                        <a href={release.BuyLink} target="_blank"><p className="poc-team-text black">{release.Plateform}</p></a>
                    </div>
                ))}
            </div>
            <div >
                <p className="poc-team-text black"><strong>Website:</strong></p>
                <a href={game.link}><p className="poc-team-text black">{game.link}</p></a>
            </div>
            <div >
                <p className="poc-team-text black"><strong>Regular Prices:</strong></p>
                <p className="poc-team-text black">{game.RegularPrices}</p>
            </div>
        </div>
    )
}

function Videos(props) {
    const game = props.game;
    return(
        <div id="videos" >
            <h1 className="poc-small-title black">Videos</h1>
            {game.videos.map(video => (
                <div key={video.id}>
                    <a href={video.link} target="_blank"><p className="poc-team-text black">{video.name}</p></a>
                    <ReactPlayer className="video-embed" url={video.link} />
                </div>
            ))}
        </div>
    )
}

function Images(props) {
    const game = props.game;
    return (
        <div id="images" >
            <h1 className="poc-small-title black">Images</h1>
            <p className="poc-team-text black">Download <a href={ Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + game.AllImages.url } download>{game.AllImages.name}</a></p>
            <Screenshots game={game} />
        </div>
    )
}

function Logos(props) {
    const game = props.game;
    return (
        <div id="icons">
            <h1 className="poc-small-title black">Logo & Icons</h1>
            <p className="poc-team-text black">Download <a href={Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + game.AllIcons.url} download>{game.AllIcons.name}</a></p>
            {game.presskit_icons.map(icon => (
                <ImageZoom url={icon.image !== null && icon.image !== undefined && Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + icon.image.url} key={icon.id} />
            ))}
        </div>
    )
}

function AwardsReco(props) {
    const game = props.game;
    return (
        <div id="awardsreco">
            <h1 className="poc-small-title black">Awards & Recognition</h1>
            {game.awards.map(award => (
                <div key={award.id}>
                    <p className="poc-team-text black">{award.description}</p>
                </div>
            ))}
        </div>
    )
}

function SelectedArticles(props) {
    const game = props.game;
    return (
        <div id="selectedarticles">
            <h1 className="poc-small-title black">Selected Articles</h1>
            {game.articles.map(article => (
                <div key={article.id}>
                    <p className="poc-team-text black italic">{article.title} {article.author} - <a href={article.link} target="_blank">{article.journal}</a></p>
                </div>
            ))}
        </div>
    )
}

function AdditionalLinks(props) {
    const game = props.game;
    return (
        <div id="additionallinks">
            <h1 className="poc-small-title black">Additional Links</h1>
            {game.additionnal_links.map(item => (
                <div key={item.id}>
                    <p className="poc-team-text black"><strong>{item.name}</strong></p>
                    <p className="poc-team-text black">Download <a href={item.linkcontent.url}>{item.linkcontent.name}</a></p>
                </div>
            ))}
        </div>
    )
}

function About(props) {
    const game = props.game;
    return (
        <div id="about">
            <h1 className="poc-small-title black">About Piece of Cake Studios</h1>
            <p className="poc-team-text black"><strong>Boilerplate</strong></p>
            <p className="poc-team-text black">{game.studios[0].description}</p>
            <p className="poc-team-text black">More information on Piece of cake Studios, our logo & relevant media are available <a href={game.studios[0].PressKit}>here.</a></p>
        </div>
    )
}

function Credits() {
    return (
        <div id="team">
            <h1 className="poc-small-title black">Piece of Cake studios Credits</h1>
            <p className="poc-team-text black"><strong>Marine Freland</strong></p>
            <p className="poc-team-text black">Co-founder, CEO</p>

            <p className="poc-team-text black"><strong>Xavier Lemaitre</strong></p>
            <p className="poc-team-text black">Co-founder, CTO</p>
        </div>
    )
}

function Contacts(props) {
    const game = props.game;
    return (
        <div id="contacts">
            <h1 className="poc-small-title black">Contacts</h1>
            <p className="poc-team-text black"><strong>Inquiries</strong></p>
            <p className="poc-team-text black">{game.studios[0].inquiriesContact}</p>

            <p className="poc-team-text black"><strong>Twitter</strong></p>
            <a href={game.studios[0].twitterlink} target="_blank"><p className="poc-team-text black">{game.studios[0].twitterlink}</p></a>

            <p className="poc-team-text black"><strong>Facebook</strong></p>
            <a href={game.studios[0].facebooklink} target="_blank"><p className="poc-team-text black">{game.studios[0].facebooklink}</p></a>

            <p className="poc-team-text black"><strong>Website</strong></p>
            <a href={game.studios[0].website} target="_blank"><p className="poc-team-text black">{game.studios[0].website}</p></a>
        </div>
    )
}

function PressKitMenu(props) {
    const game = props.game;

    const hasAwards = false;
    const hasSelectedArticles = false;
    const hasAdditionalLinks = false;

    return (
        <div className="">
            <h1 className="poc-small-title black">Piece of Cake Studios</h1>
            <a href={game.studios[0].PressKit}><p className="poc-team-text black"><strong>Studio PressKit</strong></p></a>
            <a href="#factsheet"><p className="poc-team-text black"><strong>Factsheet</strong></p></a>
            <a href="#description"><p className="poc-team-text black"><strong>Description</strong></p></a>
            <a href="#history"><p className="poc-team-text black"><strong>History</strong></p></a>
            <a href="#projects"><p className="poc-team-text black"><strong>Projects</strong></p></a>
            <a href="#videos"><p className="poc-team-text black"><strong>Videos</strong></p></a>
            <a href="#images"><p className="poc-team-text black"><strong>Images</strong></p></a>
            <a href="#icons"><p className="poc-team-text black"><strong>Logo & Icons</strong></p></a>
            {hasAwards ? (<a href="#awardsreco"><p className="poc-team-text black"><strong>Award & Recognition</strong></p></a>) : (<div />)}
            {hasSelectedArticles ? (<a href="#selectedarticles"><p className="poc-team-text black"><strong>Selected Articles</strong></p></a>) : (<div />)}
            {hasAdditionalLinks ? (<a href="#additionallinks"><p className="poc-team-text black"><strong>Additional Links</strong></p></a>) : (<div />)}
            <a href="#about"><p className="poc-team-text black"><strong>About Piece of Cake Studios</strong></p></a>
            <a href="#team"><p className="poc-team-text black"><strong>Team</strong></p></a>
            <a href="#contacts"><p className="poc-team-text black"><strong>Contacts</strong></p></a>
        </div>
    )
}

const game = [];

class PressKitHome extends React.Component {
    state = {
        game
    }

    async componentDidMount() {

        const gameRes = await Axios({
            method: 'GET',
            url: Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + '/games?name=' + Config.website.name
        })

        const game = gameRes.data

        this.setState({ game })
    }
    render() {
        const { game } = this.state

        const hasAwards = false;
        const hasSelectedArticles = false;
        const hasAdditionalLinks = false;

        if (game[0] !== undefined) {
            return (
                <div>
                    <div className="page-width">
                        <div className="presskit-page">
                            <div className="presskit-content-left">
                                <PressKitMenu game={game[0]}/>
                            </div>
                            <div className="presskit-content-right">
                                <img alt="hacktag" className="center-img" src={Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + game[0].BannerImage.url} />
                                <div className="presskit-top">
                                    <Factsheet title="Factsheet" game={game[0]} />
                                    <div className="">
                                        <Paragraph title="Description" text={game[0].descriptionComplete} name="description" />
                                        <Paragraph title="History" text={game[0].History} name="history"/>
                                        <Paragraph title="Features" text={game[0].Features} name="features"/>
                                    </div>
                                </div>
                                <Videos game={game[0]} />
                                <Images game={game[0]} />
                                <Logos game={game[0]} />
                                {hasAwards ? (<AwardsReco game={game[0]} />) : (<div/>)}
                                {hasSelectedArticles ? (<SelectedArticles game={game[0]} />) : (<div />)}
                                {hasAdditionalLinks ? (<AdditionalLinks game={game[0]} />) : (<div />)}
                                <About game={game[0]} />
                                <div className="presskit-contacts">
                                    <Credits />
                                    <Contacts game={game[0]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    Wait... Game still not found. Check your connection or the database.
                </div>
            );
        }
    }
}

export default PressKitHome;