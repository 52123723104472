import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Content = (props) => (
    <Zoom>
            <a href={props.url}>
                <img alt="hacktag-screenshot" src={props.url} />
            </a>
    </Zoom>
)

function ImageZoomHover(props) {
    const url = props.url;
    return (
        <div className="screenshot">
            <Content url={url} />
            <a href={url} target="_blank">
                <div className="screenshot-hovercontent zoom-image">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 136 136.21852" width="40px" height="40px">
                        <g fill="#FFFFFF">
                        <path d="M 93.148438 80.832031 C 109.5 57.742188 104.03125 25.769531 80.941406 9.421875 C 57.851562 -6.925781 25.878906 -1.460938 9.53125 21.632812 C -6.816406 44.722656 -1.351562 76.691406 21.742188 93.039062 C 38.222656 104.707031 60.011719 105.605469 77.394531 95.339844 L 115.164062 132.882812 C 119.242188 137.175781 126.027344 137.347656 130.320312 133.269531 C 134.613281 129.195312 134.785156 122.410156 130.710938 118.117188 C 130.582031 117.980469 130.457031 117.855469 130.320312 117.726562 Z M 51.308594 84.332031 C 33.0625 84.335938 18.269531 69.554688 18.257812 51.308594 C 18.253906 33.0625 33.035156 18.269531 51.285156 18.261719 C 69.507812 18.253906 84.292969 33.011719 84.328125 51.234375 C 84.359375 69.484375 69.585938 84.300781 51.332031 84.332031 C 51.324219 84.332031 51.320312 84.332031 51.308594 84.332031 Z M 51.308594 84.332031 " />
                        </g>
                    </svg>
                </div>
            </a >
            <a href={url} download target="_blank">
            <div className="screenshot-hovercontent save-image">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="40px" height="40px">
                        <g fill="#FFFFFF">
                            <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4
                            c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z
				            M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z"/>
                            <path d="M5268.4 2410.3c-.6 0-1 .4-1 1 0 .6.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.6-.4-1-1-1H5268.4zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1 0 .6.4 1 1 1h4.3c.6 0 1-.4 1-1C5273.7 2414.1 5273.3 2413.7 5272.7 2413.7zM5272.7 2417h-4.3c-.6 0-1 .4-1 1 0 .6.4 1 1 1h4.3c.6 0 1-.4 1-1C5273.7 2417.5 5273.3 2417 5272.7 2417z"/><g><path d="M94.6,25.9L73.7,5c-1.6-1.6-3.8-2.5-6-2.5H58V20c0,1.8-1.5,3.3-3.3,3.3H27.3c-1.8,0-3.3-1.5-3.3-3.3V2.5H11.4
				            c-4.7,0-8.5,3.8-8.5,8.5v78c0,4.7,3.8,8.5,8.5,8.5h77.3c4.7,0,8.5-3.8,8.5-8.5V31.9C97.1,29.7,96.2,27.5,94.6,25.9z M76.9,78.2
				            c0,1.8-1.5,3.3-3.3,3.3H26.3c-1.8,0-3.3-1.5-3.3-3.3V45.4c0-1.8,1.5-3.3,3.3-3.3h47.4c1.8,0,3.3,1.5,3.3,3.3V78.2z"/>
                            <path d="M44.2 17.7h6.4c.7 0 1.3-.6 1.3-1.3V3.8c0-.7-.6-1.3-1.3-1.3h-6.4c-.7 0-1.3.6-1.3 1.3v12.5C42.9 17.1 43.5 17.7 44.2 17.7zM63.9 51.3H36.1c-1.9 0-3.5 1.5-3.5 3.5 0 1.9 1.5 3.5 3.5 3.5h27.7c1.9 0 3.5-1.5 3.5-3.5C67.3 52.8 65.8 51.3 63.9 51.3zM63.9 65.5H36.1c-1.9 0-3.5 1.5-3.5 3.5 0 1.9 1.5 3.5 3.5 3.5h27.7c1.9 0 3.5-1.5 3.5-3.5C67.3 67.1 65.8 65.5 63.9 65.5z" /></g>
                        </g>
                    </svg>
                </div>
            </a >
        </div>
    )
}

export default ImageZoomHover;