import React from 'react';
import ReactPlayer from "react-player"
import Config from '../Config.json';

import Awards from './AwardsImage';
import Articles from './ArticlesDetailedList';

import delve from 'dlv';

function Trailer(props) {
    const game = props.game;

    const backgroundImgUrl = delve(delve(game, 'PresentationBg', null), 'url', null);

    const hasPresentationBg = backgroundImgUrl != null;

    const awards = delve(game, 'awards', null);
    const articles = delve(game, 'articles', null);

    const hasAwards = awards != null;
    const hasArticles = articles != null;


    return (

        <div className="full-background trailer-block-container" style={{ backgroundImage: !hasPresentationBg ? null : `url(${Config.strapi.protocol + '://' + Config.strapi.address + ':' + Config.strapi.port + backgroundImgUrl} )` }}>
            {hasAwards ? (<Awards game={game} />) : (<div />)}
            <div className="trailer-block">
                <ReactPlayer className="video-embed" url={game.YoutubeTrailer} playing muted loop />
                <iframe title="steam-widget" className="steam-widget-embed" src={game.SteamWidget} frameborder="0" width="646" height="190"></iframe>
                
            </div>
            {hasArticles ? (<Articles game={game} />) : (<div />)}
        </div>
    )
}

export default Trailer;